<script>
	export let style = '';
</script>

<div class="wrapper" {style}>
	<div class="line" />
	<span><slot /></span>
	<div class="line" />
</div>

<style>
	.wrapper {
		font-size: 1.2rem;
		display: flex;
		width: 90%;
		align-items: center;
		gap: 10px;
		margin: 20px 0px;
		/* font-style: italic; */
		color: rgb(72, 72, 72);
	}
	.line {
		flex-grow: 1;
		height: 1px;
		background-color: #d0d0d0;
		/* min-width: 50px; */
		min-width: 0;
	}
	span {
		position: relative;
		top: -2px;
	}
</style>
